import React, { useState, useEffect } from "react";
import Food from "../../assets/food/food_main.png";
import Food2 from "../../assets/food/eclair.png";
import Food3 from "../../assets/food/apple_tart.png";
import Food5 from "../../assets/food/chocolate_mousse.png";
import Food6 from "../../assets/food/chocolate_cake.png";
import Food7 from "../../assets/food/vegetable_quiche.png";
import Food8 from "../../assets/food/vanilla_mousse.png";
import Food9 from "../../assets/food/Orange_tart.png";
import Food10 from "../../assets/food/Boxegna2.png";
import Food11 from "../../assets/food/tuxedo.png";
import Food13 from "../../assets/food/strawberry_two.png";
import Food14 from "../../assets/food/crossiant.png";
import { motion } from "framer-motion";
import logo from "../../assets/main_logo.png";

export const slideUp = (delay) => {
  return {
    hidden: {
      y: "100%",
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: delay,
      },
    },
  };
};

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const foodImages = [
    Food,
    Food2,
    Food3,
    Food5,
    Food6,
    Food7,
    Food8,
    Food9,
    Food10,
    Food11,
    Food13,
    Food14,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % foodImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [foodImages.length]);

  return (
    <main>
      <div className="container min-h-[600px] flex justify-center mt-28 relative">
        {/* <div className="absolute left-0 top-0 h-full w-1/2 bg-yellow-500 z-[-1]"></div>{" "} */}
        {/* {{ edit_1 }} */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-24 place-items-center justify-between">
          {/* text content */}
          <div className="space-y-3 mt-14 text-center md:text-left md:mt-0">
            {/* <motion.h1
              variants={slideUp(0.5)}
              initial="hidden"
              whileInView="show"
              className="relative text-5xl lg:text-8xl xl:text-8xl font-bold text-center text-outline font-custom text-white2"
            >
              Chez Jean Pierre Patisserie
            </motion.h1> */}
            <motion.h1
              variants={slideUp(1)}
              initial="hidden"
              whileInView="show"
              className="relative text-5xl lg:text-7xl xl:text-9xl font-bold font-custom text-white2"
            >
              <img src={logo} alt="logo_image" />
            </motion.h1>
            <motion.p
              variants={slideUp(1.5)}
              initial="hidden"
              whileInView="show"
              whileHover={{ scale: 1.5 }} // Added hover animation
              animate={{
                color: ["#ffffff", "#ffcc00", "#ff6699", "#66ccff", "#ffffff"],
              }} // Dark theme colors
              transition={{ duration: 2, repeat: Infinity }} // Repeat the color animation
              className="text-5xl font-witt flex flex-col items-center cursor-auto transition-transform duration-300" // Removed hover scale from class
            >
              <span>ALL YOU NEED</span>
              <span className="text-center">IS</span>
              <span>LOVE & PASTRY</span>
            </motion.p>
            {/* <motion.button
              variants={slideUp(2)}
              initial="hidden"
              whileInView="show"
              className="btn-primary inline-block !mt-10 bg-gray-800"
            >
              <IoCartOutline className="inline mr-2" />
              Order Now
            </motion.button> */}
          </div>
          {/* image content */}
          <div className="relative">
            <motion.img
              key={currentImageIndex}
              src={foodImages[currentImageIndex]}
              alt="image"
              className="w-[550px] img-shadow"
              initial={{ opacity: 0, rotate: 120, x: 200, y: 100 }}
              whileInView={{ opacity: 1, rotate: 0, x: 0, y: 0 }}
              transition={{ duration: 0.8 }}
            />
            {/* <motion.img
              initial={{ opacity: 0, rotate: 20 }}
              whileInView={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 0.8 }}
              src={Spoon}
              alt=""
              className="w-[350px] absolute top-[350px] left-[50px] rotate-[45deg] img-shadow"
            />
            <motion.img
              initial={{ opacity: 0, rotate: 20 }}
              whileInView={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 0.8 }}
              src={Spoon}
              alt=""
              className="w-[350px] absolute top-[375px] right-[10px] rotate-[270deg] img-shadow"
            /> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Hero;
