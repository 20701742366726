import React, { useState, useEffect, useRef } from "react";
import logo from "../../../src/assets/main_logo_header.png";
import { IoLogoInstagram, IoLogoFacebook, IoLogoTiktok } from "react-icons/io5";
import { motion } from "framer-motion";

const NavMenu = [
  {
    id: 1,
    title: "Home",
    path: "/",
    delay: 0.1,
  },
  {
    id: 2,
    title: "About",
    path: "/about",
    delay: 0.2,
  },
  {
    id: 3,
    title: "Menu",
    path: "/menu",
    delay: 0.3,
  },
  {
    id: 4,
    title: "Contact Us",
    path: "/contact",
    delay: 0.4,
  },
];

const slideDown = (delay) => {
  return {
    initial: {
      y: "-100%",
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: delay,
      },
    },
  };
};

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      className={`fixed font-custom top-0 left-0 right-0 z-50 transition-all duration-300`}
      style={{
        background: scrolled
          ? "rgba(0, 0, 0, 0.5)" // Adjusted to more transparent background when scrolled
          : "transparent", // Transparent background when not scrolled
      }}
    >
      <div
        className={`absolute inset-0 transition-opacity duration-300`}
        style={{
          background: scrolled
            ? "linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)" // Adjusted to more transparent gradient when scrolled
            : "linear-gradient(to top, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%)",
        }}
      ></div>
      <div className="container relative mx-auto flex justify-between items-center py-4 px-6">
        {/* logo section */}
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          src={logo}
          alt="logo"
          className="w-36"
        />
        {/* Hamburger Menu for small devices */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <div className={`hamburger ${isOpen ? "open" : ""}`}>
              <span className="block w-8 h-1 bg-gray-700 mb-1"></span>
              <span className="block w-8 h-1 bg-gray-700 mb-1"></span>
              <span className="block w-8 h-1 bg-gray-700"></span>
            </div>
          </button>
          {isOpen && (
            <motion.div
              ref={dropdownRef} // Reference to the dropdown
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="absolute right-0 bg-gradient-to-t from-black to-transparent border border-gray-700 shadow-lg rounded-lg mt-2 p-4 transition-all duration-300 ease-in-out"
            >
              <ul className="flex flex-col gap-4">
                {NavMenu.map((menu) => (
                  <li key={menu.id}>
                    <a
                      href={menu.path}
                      className={`block text-gray-200 font-custom hover:text-gray-400 font-semibold transition-colors duration-200`}
                    >
                      {menu.title}
                    </a>
                  </li>
                ))}
                {/* Social Icons in Dropdown */}
                <div className="flex justify-center space-x-4 mt-4">
                  <a
                    href="https://www.instagram.com/chezjeanpierreparisserie/profilecard/?igsh=YjdmaDdpc3dwMmF0"
                    className="h-[40px] w-[40px] grid place-items-center rounded-full text-black bg-white hover:bg-[#E4405F] transition-colors duration-300 shadow-md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoInstagram />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61550514615213&mibextid=ZbWKwL"
                    className="h-[40px] w-[40px] grid place-items-center rounded-full text-black bg-white hover:bg-[#1877F2] transition-colors duration-300 shadow-md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoFacebook />
                  </a>
                  <a
                    href="https://www.tiktok.com/@chezjp?is_from_webapp=1&sender_device=pc"
                    className="h-[40px] w-[40px] grid place-items-center rounded-full text-black bg-white hover:bg-[#000000] transition-colors duration-300 shadow-md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoTiktok />
                  </a>
                </div>
              </ul>
            </motion.div>
          )}
        </div>
        {/* menu section for larger devices */}
        <div className="hidden md:block">
          <ul className="flex gap-6">
            {NavMenu.map((menu) => {
              return (
                <motion.li
                  variants={slideDown(menu.delay)}
                  initial="initial"
                  animate="animate"
                  key={menu.id}
                  className="nav-menu"
                  data-delay={menu.delay}
                >
                  <a
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    href={menu.path}
                    className={`inline-block hover:scale-110 duration-500 px-2 py-2 text-6xl font-extrabold ${
                      scrolled
                        ? "text-gray-400 hover:text-gray-300"
                        : "text-gray-700 hover:text-white"
                    }`}
                  >
                    {menu.title}
                  </a>
                </motion.li>
              );
            })}
          </ul>
        </div>
        {/* button section */}
        <motion.div
          variants={slideDown(1)}
          initial="initial"
          animate="animate"
          className="hidden md:flex flex-row space-x-2"
        >
          <a
            href="https://www.instagram.com/chezjeanpierreparisserie/profilecard/?igsh=YjdmaDdpc3dwMmF0"
            className={`h-[40px] w-[40px] grid place-items-center rounded-full ${
              scrolled ? "text-black bg-white" : "text-white bg-dark"
            } hover:bg-[#E4405F] hover:text-white transition-colors hover:scale-110 duration-900`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61550514615213&mibextid=ZbWKwL"
            className={`h-[40px] w-[40px] grid place-items-center rounded-full ${
              scrolled ? "text-black bg-white" : "text-white bg-dark"
            } hover:bg-[#1877F2] hover:text-white transition-colors hover:scale-110 duration-900`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook />
          </a>
          <a
            href="https://www.tiktok.com/@chezjp?is_from_webapp=1&sender_device=pc"
            className={`h-[40px] w-[40px] grid place-items-center  rounded-full ${
              scrolled ? "text-black bg-white" : "text-white bg-dark"
            } hover:bg-[#000000] hover:text-white transition-colors hover:scale-110 duration-900`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTiktok />
          </a>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;
