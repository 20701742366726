import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar"; // Adjust the import path as necessary
import Hero from "./components/Hero/Hero"; // Adjust the import path as necessary
import background_four from "../src/assets/bg_now.jpg"; // Adjust the import path as necessary
import HomeMenu from "./components/HomeMenu/HomeMenu";
import Popular from "./components/Popular/Popular";
// import Testimonial from "./components/Testimonial/Testimonial";
import Footer from "./components/Footer/Footer";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Menu from "./pages/Menu/Menu";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/*"
          element={
            <div
              className="overflow-hidden text-black"
              style={{
                backgroundImage: `url(${background_four})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
              }}
            >
              <Navbar />
              <Routes>
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/menu" element={<Menu />} />
                <Route
                  path="/"
                  element={
                    <>
                      <Hero />
                      <HomeMenu />
                      <Popular />
                      {/* <Testimonial /> */}
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <>
                      <Hero />
                      <HomeMenu />
                      <Popular />
                      {/* <Testimonial /> */}
                      <Footer />
                    </>
                  }
                />
              </Routes>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
