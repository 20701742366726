import React, { useState, useEffect } from "react";
import torta from "../../assets/icons/birthday-cake.png";
import breads from "../../assets/icons/breads.png";
import cake from "../../assets/icons/cake.png";
import beverages from "../../assets/icons/cocktail.png";
import breakfast from "../../assets/icons/croissant.png";
import tart from "../../assets/icons/egg-tart.png";
import quiche from "../../assets/icons/quiche.png";
import lunch from "../../assets/icons/serving-dish.png";
import { motion } from "framer-motion";
import { slideUp } from "../Hero/Hero";
import axiosInstance from "../../utils/axios";

const categories = [
  { name: "Tarts, Quiches and Cakes", icons: [tart, quiche, cake] },
  { name: "Breakfast and Snacks", icon: breakfast },
  { name: "Lunch", icon: lunch },
  { name: "Breads", icon: breads },
  { name: "Torta", icon: torta },
  { name: "Beverages", icon: beverages },
];

const HomeMenu = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [foodItems, setFoodItems] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (selectedCategory) {
      fetchFoodItems(selectedCategory);
    }
  }, [selectedCategory]);

  const handleFoodItemClick = (item) => {
    setSelectedFood(item);
    setCurrentImageIndex(0); // Reset image index when a new food is selected
  };
  useEffect(() => {
    let intervalId;
    if (selectedFood && selectedFood.images.length > 1) {
      intervalId = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % selectedFood.images.length
        );
      }, 3000); // Change image every 3 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [selectedFood]);
  const fetchFoodItems = async (category) => {
    try {
      const response = await axiosInstance.get(
        `/api/food/items?category=${category}`
      );
      setFoodItems(response.data.foodItems);
    } catch (error) {
      console.error("Error fetching food items:", error);
    }
  };

  return (
    <section className="">
      <div className="container py-12">
        <motion.h3
          variants={slideUp(0.8)}
          initial="hidden"
          whileInView="show"
          className="text-8xl text-dark text-center font-bold uppercase py-8 font-custom  bg-clip-text"
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
        >
          Food&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Categories
        </motion.h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 mb-8">
          {categories.map((category, index) => (
            <motion.button
              key={category.name}
              variants={slideUp(index * 0.1)}
              initial="hidden"
              whileInView="show"
              className={`p-4 rounded-lg shadow-md transition-all duration-300 flex flex-col items-center justify-center space-y-2 ${
                selectedCategory === category.name
                  ? "bg-white text-black"
                  : "bg-gray-800 bg-opacity-50 text-white hover:bg-gray-700"
              }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              {Array.isArray(category.icons) ? (
                <div className="flex justify-center space-x-2">
                  {category.icons.map((icon, iconIndex) => (
                    <img
                      key={iconIndex}
                      src={icon}
                      alt={`${category.name} icon ${iconIndex + 1}`}
                      className={`w-8 h-8 object-contain transition-all duration-300 ${
                        selectedCategory === category.name
                          ? "filter brightness-0"
                          : "filter invert"
                      }`}
                    />
                  ))}
                </div>
              ) : (
                <img
                  src={category.icon}
                  alt={category.name}
                  className={`w-12 h-12 object-contain transition-all duration-300 ${
                    selectedCategory === category.name
                      ? "filter brightness-0"
                      : "filter invert"
                  }`}
                />
              )}
              <span className="text-sm font-medium text-center">
                {category.name}
              </span>
            </motion.button>
          ))}
        </div>
        {selectedCategory && (
          <div className="mt-8">
            <h4 className="text-6xl font-semibold mb-6 text-dark text-center font-vibes ">
              {selectedCategory}
            </h4>
            <div className="overflow-x-auto overflow-y-hidden">
              <div className="flex space-x-6 pb-4">
                {foodItems.map((item) => (
                  <motion.div
                    key={item._id}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="group bg-gray-800 bg-opacity-50 rounded-lg shadow-lg p-4 flex-shrink-0 w-72 flex flex-col items-center cursor-pointer transform transition-all duration-300 hover:scale-105"
                    onClick={() => handleFoodItemClick(item)}
                  >
                    <div className="relative w-full h-48 mb-4 overflow-hidden rounded-t-lg">
                      <img
                        src={item.images[0]}
                        alt={item.name}
                        className="w-full h-full object-cover transition-all duration-700 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-20 transition-all duration-300"></div>
                    </div>
                    <div className="text-center">
                      <motion.h3
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className="text-2xl font-semibold font-ephesis text-white mb-2"
                      >
                        {item.name}
                      </motion.h3>
                      <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="text-lg text-gray-300 font-witt font-medium mb-3"
                      >
                        {item.price.toFixed(2)} birr
                      </motion.p>
                      {/* <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        className="text-sm text-gray-400 line-clamp-2 mb-4"
                      >
                        {item.description}
                      </motion.p> */}
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                      >
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="bg-white text-black px-4 py-2 rounded-full font-witt text-sm font-medium hover:bg-gray-300 transition-colors duration-300"
                        >
                          View Details
                        </motion.button>
                      </motion.div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )}
        {selectedFood && (
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-12 rounded-lg overflow-hidden bg-gray-900 bg-opacity-50"
          >
            <div className="container py-12 px-6 md:px-12">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                <div className="relative">
                  <motion.img
                    key={currentImageIndex}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    src={selectedFood.images[currentImageIndex]}
                    alt={selectedFood.name}
                    className="w-full h-[400px] object-cover rounded-lg shadow-lg"
                  />
                  {selectedFood.images.length > 1 && (
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                      {selectedFood.images.map((_, index) => (
                        <div
                          key={index}
                          className={`w-3 h-3 rounded-full ${
                            index === currentImageIndex
                              ? "bg-white"
                              : "bg-gray-600"
                          }`}
                        ></div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="space-y-6 font-witt">
                  <h2 className="text-6xl font-ephesis font-bold text-white border-b-2 border-gray-700 pb-2">
                    {selectedFood.name}
                  </h2>
                  <p className="text-2xl font-semibold text-gray-300">
                    {selectedFood.price.toFixed(2)} birr
                  </p>
                  <p className="text-gray-400 italic">
                    {selectedFood.description}
                  </p>
                  {/* 
                  {selectedFood.ingredients && (
                    <div className="space-y-2">
                      <h3 className="text-2xl font-semibold text-white ">
                        Ingredients
                      </h3>
                      <ul className="grid grid-cols-2 gap-2 text-gray-300 ">
                        {selectedFood.ingredients
                          .split(",")
                          .map((ingredient, index) => (
                            <li key={index} className="flex items-center">
                              <span className="mr-2 text-white">•</span>
                              {ingredient.trim()}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )} */}

                  {selectedFood.extraToppings && (
                    <div className="space-y-2">
                      <h3 className="text-2xl font-semibold text-white font-witt">
                        Extra Toppings
                      </h3>
                      <ul className="grid grid-cols-2 gap-2 text-gray-300 font-witt">
                        {selectedFood.extraToppings
                          .split(",")
                          .map((topping, index) => (
                            <li key={index} className="flex items-center">
                              <span className="mr-2 text-white">+</span>
                              {topping.trim()}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}

                  {selectedFood.allergens && (
                    <div className="space-y-2">
                      <h3 className="text-xl font-semibold text-white">
                        Allergens
                      </h3>
                      <p className="text-gray-300 bg-gray-800 p-2 rounded-md">
                        {selectedFood.allergens}
                      </p>
                    </div>
                  )}

                  {selectedFood.nutritionalInfo && (
                    <div className="space-y-2">
                      <h3 className="text-xl font-semibold text-white">
                        Nutritional Information
                      </h3>
                      <ul className="grid grid-cols-2 gap-3 text-gray-300">
                        {Object.entries(selectedFood.nutritionalInfo).map(
                          ([key, value]) => (
                            <li
                              key={key}
                              className="flex justify-between bg-gray-800 p-2 rounded-md"
                            >
                              <span className="font-medium">{key}:</span>
                              <span>{value}</span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </div>
    </section>
  );
};

export default HomeMenu;
