import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
  notification,
  Spin,
} from "antd"; // Import notification
import { UploadOutlined } from "@ant-design/icons"; // Import Upload icon
import "antd/dist/reset.css"; // Import Ant Design styles
import axiosInstance from "../../utils/axios";

const { Option } = Select;

const AddFood = () => {
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleFinish = async (values) => {
    const extraToppingsArray = values.extraToppings
      .split(",")
      .map((item) => item.trim());

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("category", values.category);
    formData.append("foodType", values.foodType);
    formData.append("price", values.price);
    formData.append("readyTime", values.readyTime);
    formData.append("description", values.description);
    formData.append("extraToppings", extraToppingsArray.join(","));

    imageList.forEach((file) => {
      formData.append("images", file);
    });

    setLoading(true); // Set loading to true before the upload starts

    try {
      const response = await axiosInstance.post("/api/food/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        form.resetFields(); // Reset form fields after submission
        setImageList([]); // Clear image list
        notification.success({ message: "Food item added successfully!" }); // Show success notification
        window.location.reload(); // Reload the page after adding
      }
    } catch (error) {
      console.error("Error adding food item:", error);
      message.error("Failed to add food item. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after the upload completes
    }
  };

  const handleImageChange = (info) => {
    if (info.fileList.length > 0) {
      setImageList(info.fileList.map((file) => file.originFileObj));
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
      {loading && <Spin tip="Uploading food item..." />}{" "}
      {/* Show loading indicator */}
      <h2 className="text-2xl font-bold mb-4 text-center">Add New Food Item</h2>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          name="name"
          label="Food Name"
          rules={[{ required: true, message: "Please input the food name!" }]}
        >
          <Input placeholder="Enter food name" />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <Select placeholder="Select a category">
            <Option value="Tarts, Quiches and Cakes">
              Tarts, Quiches and Cakes
            </Option>
            <Option value="Breakfast and Snacks">Breakfast and Snacks</Option>
            <Option value="Lunch">Lunch</Option>
            <Option value="Breads">Breads</Option>
            <Option value="Torta">Torta</Option>
            <Option value="Beverages">Beverages</Option>
            {/* Add more categories as needed */}
          </Select>
        </Form.Item>

        <Form.Item
          name="foodType"
          label="Food Type"
          rules={[{ required: true, message: "Please select a food type!" }]}
        >
          <Select placeholder="Select food type">
            <Option value="veg">Vegetarian</Option>
            <Option value="non-veg">Non-Vegetarian</Option>
            <Option value="vegan">Vegan</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: "Please input the price!" }]}
        >
          <Input type="number" placeholder="Enter price" />
        </Form.Item>

        <Form.Item
          name="readyTime"
          label="Ready Time (minutes)"
          rules={[{ required: true, message: "Please input the ready time!" }]}
        >
          <Input type="number" placeholder="Enter ready time in minutes" />
        </Form.Item>

        <Form.Item
          name="images"
          label="Upload Images"
          valuePropName="fileList"
          getValueFromEvent={handleImageChange}
        >
          <Upload
            beforeUpload={() => false} // Prevent automatic upload
            multiple
            listType="picture"
            onChange={handleImageChange}
          >
            <Button icon={<UploadOutlined />}>Upload Images</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Enter a description" />
        </Form.Item>

        <Form.Item name="extraToppings" label="Extra Toppings">
          <Input placeholder="Enter extra toppings, separated by commas" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            loading={loading}
          >
            Add Food Item
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddFood;
